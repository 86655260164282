import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'

export const ServicePageTemplate = ({
  title,
  titleEn,
  description,
  helmet,
  content,
  content2monthlys,
  content2fiduciarys,
  content3Table1monthlys,
  content3Table1fiduciarys,
  content3Table2monthlys,
  content3Table2fiduciarys,
  content3Table3monthlys,
  content3Table3fiduciarys,

}) => (
  <main className="l-main">
    <div className="l-container">
      {helmet || ''}  
      <h1 className="c-pageTitle" data-spacingsp="mb7">
        <span className="c-pageTitle__container">
          <span className="c-pageTitle__textJa">{title}</span>
          <span className="c-pageTitle__textEn">{titleEn}</span>
        </span>
      </h1>
    </div>
    <div className="l-container--wide u-positionRelative" style={{zIndex:10}}>
      <ul className="p-breadCrumbs">
        <li className="p-breadCrumbs__item"><Link to="/" className="p-breadCrumbs__link">ホーム</Link></li>
        <li className="p-breadCrumbs__item isCurrent">{title}</li>
      </ul>
    </div>
    <div className="p-serviceSection--header">
      <div className="l-container u-alignCenter">
        <img src="/img/service/illust_intro.svg" alt="サービスイメージイラスト" data-spacing="mb5" data-spacingsp="mb2"/>
        <p className="u-lineHeight200 u-whiteSpacePreWrap u-whiteSpaceSpNormal u-alignMdLeft u-alignSpLeft u-font--sp14" data-spacing="mb5" data-spacingsp="mb3">
          {description}
        </p>
      </div>
      <h2 className="u-alignCenter">
        <span
          className="u-displayBlock u-font--36 u-font--medium u-font--marineBlue u-font--sp20"
          data-spacingsp="mb1"
        >
          ＼ こんなお悩みありませんか？ ／
        </span>
        <span
          className="u-displayBlock u-font--16 u-font--termina u-font--sp12"
        >
          DO YOU HAVE <span className="u-font--medium"> SUCH TROUBLES?</span>
        </span>
      </h2>
    </div>
    <section className="p-serviceSection">
      <header className="p-serviceProblem">
        <div className="l-container">
          <span className="p-serviceProblem__number">
            <span className="p-serviceProblem__numberCopy">お悩み</span>
            <span className="p-serviceProblem__numberNum">1</span>
          </span>
          <h3 className="p-serviceProblem__title">{content.content1.problem.title}</h3>
          <div className="p-serviceProblem__container">
            <figure className="p-serviceProblem__figure">
              <img src="/img/service/title_section1.svg" alt="サービスイメージイラスト" className="p-serviceProblem__img" />
            </figure>
            <ul className="p-serviceProblem__list">
              <li className="p-serviceProblem__item">{content.content1.problem.item1}</li>
              <li className="p-serviceProblem__item">{content.content1.problem.item2}</li>
              <li className="p-serviceProblem__item">{content.content1.problem.item3}</li>
              <li className="p-serviceProblem__item">{content.content1.problem.item4}</li>
              <li className="p-serviceProblem__item">{content.content1.problem.item5}</li>
            </ul>
          </div>
        </div>
      </header>
      <article className="p-serviceSolution">
        <div className="l-container">
          <p className="p-serviceSolution__bub">アーバが解決！</p>
          <div className="p-serviceSolution__flex">
            <figure className="p-serviceSolution__figure u-mqHidden--sm">
              <img src="/img/service/illust_section1.svg" alt="" />
            </figure>
            <div className="p-serviceSolution__body">
              <h4 className="p-serviceSolution__title">
                {content.content1.solution.title}
              </h4>
              <figure className="p-serviceSolution__figure u-mqShow--smBlock">
                <img src="/img/service/illust_section1.svg" alt="" />
              </figure>
              <p className="p-serviceSolution__copy">
                {content.content1.solution.description}  
              </p>
            </div>
          </div>
          <p className="u-alignCenter" data-spacing="pt8">
            <Link to="/work/" className="c-button--skewYellow p-serviceSolution__button">
              お客様の声
              <span className="c-icon--arrow c-button__arrow" />
            </Link>
          </p>
        </div>
      </article>
    </section>
    <section className="p-serviceSection">
      <header className="p-serviceProblem" data-spacingsp="pb4">
        <div className="l-container">
          <span className="p-serviceProblem__number">
            <span className="p-serviceProblem__numberCopy">お悩み</span>
            <span className="p-serviceProblem__numberNum">2</span>
          </span>
          <h3 className="p-serviceProblem__title">{content.content2.problem.title}</h3>
          <div className="p-serviceProblem__container">
            <figure className="p-serviceProblem__figure">
              <img src="/img/service/title_section2.svg" alt="サービスイメージイラスト" className="p-serviceProblem__img" />
            </figure>
            <ul className="p-serviceProblem__list">
              <li className="p-serviceProblem__item">{content.content2.problem.item1}</li>
              <li className="p-serviceProblem__item">{content.content2.problem.item2}</li>
            </ul>
          </div>
        </div>
      </header>
      <article className="p-serviceSolution">
        <div className="l-container">
          <p className="p-serviceSolution__bub">アーバが解決！</p>
          <div className="p-serviceSolution__flex">
            <figure className="p-serviceSolution__figure u-mqHidden--sm">
              <img src="/img/service/illust_section2.svg" alt="" />
            </figure>
            <div className="p-serviceSolution__body">
              <h4 className="p-serviceSolution__title">
                {content.content2.solution.title}
              </h4>
              <figure className="p-serviceSolution__figure u-mqShow--smBlock" data-spacingsp="ph8">
                <img src="/img/service/illust_section2.svg" alt="" />
              </figure>
              <p className="p-serviceSolution__copy">
                {content.content2.solution.description}  
              </p>
            </div>
          </div>
          <div className="c-serviceTable" data-spacing="pt7">
            <div className="c-serviceTable__container--head">
              <div className="c-serviceTable__dt" />
              <p className="c-serviceTable__dd--head u-mqHidden--sm">月額サービス</p>
              <p className="c-serviceTable__dd--head u-mqHidden--sm">受託開発サービス</p>
            </div>
            <dl className="c-serviceTable__container">
              <dt className="c-serviceTable__dt">
                <span className="c-serviceTable__title">
                  {content.content2.solution.table1.title}  
                </span>
              </dt>
              <dd className="c-serviceTable__dd">
                <p className="c-serviceTable__dd--head u-mqShow--smBlock">月額サービス</p>
                <div data-spacing="pv4" data-spacingsp="pv3">
                  <ul className="c-serviceTable__list">
                    {content2monthlys.map(content2monthly => (
                      <li key={content2monthly + `content2monthly`}>
                        {content2monthly}
                      </li>
                    ))}
                  </ul>
                </div>
              </dd>
              <dd className="c-serviceTable__dd">
                <p className="c-serviceTable__dd--head u-mqShow--smBlock">受託開発サービス</p>
                <div data-spacing="pv4" data-spacingsp="pv3">
                <ul className="c-serviceTable__list">
                    {content2fiduciarys.map(content2fiduciary => (
                      <li key={content2fiduciary + `content2fiduciary`}>
                        {content2fiduciary}
                      </li>
                    ))}
                  </ul>
                </div>
              </dd>
            </dl>
          </div>  
        </div>
      </article>
    </section>
    <section className="p-serviceSection" data-spacing="mb8 pb8" data-spacingsp="pb0">
      <header className="p-serviceProblem">
        <div className="l-container">
          <span className="p-serviceProblem__number">
            <span className="p-serviceProblem__numberCopy">お悩み</span>
            <span className="p-serviceProblem__numberNum">3</span>
          </span>
          <h3 className="p-serviceProblem__title">{content.content3.problem.title}</h3>
          <div className="p-serviceProblem__container">
            <figure className="p-serviceProblem__figure">
              <img src="/img/service/title_section3.svg" alt="サービスイメージイラスト" className="p-serviceProblem__img" />
            </figure>
            <ul className="p-serviceProblem__list">
              <li className="p-serviceProblem__item">{content.content3.problem.item1}</li>
              <li className="p-serviceProblem__item">{content.content3.problem.item2}</li>
              <li className="p-serviceProblem__item">{content.content3.problem.item3}</li>
            </ul>
          </div>
        </div>
      </header>
      <article className="p-serviceSolution">
        <div className="l-container">
          <p className="p-serviceSolution__bub">アーバが解決！</p>
          <div className="p-serviceSolution__flex">
            <figure className="p-serviceSolution__figure u-mqHidden--sm">
              <img src="/img/service/illust_section3.svg" alt="" />
            </figure>
            <div className="p-serviceSolution__body">
              <h4 className="p-serviceSolution__title">
                {content.content3.solution.title}
              </h4>
              <figure className="p-serviceSolution__figure u-mqShow--smBlock" data-spacingsp="ph8">
                <img src="/img/service/illust_section3.svg" alt="" />
              </figure>
              <p className="p-serviceSolution__copy">
                {content.content3.solution.description}  
              </p>
            </div>
          </div>
          <h4 className="u-alignCenter" data-spacing="mt8 pt5" data-spacingsp="mt6 pt0">
            <span className="u-font--32 u-font--sp20 u-font--medium u-displayBlock" data-spacing="mb1">
              月額サービスと<br className="u-mqShow--smBlock"></br>受託開発サービスの比較
            </span>
            <span className="u-font--termina u-font--medium u-font--sp10">
              COMPARE OF MONTHLY &amp; CONTRACT SERVICE
            </span>
          </h4>  
          <div className="c-serviceTable" data-spacing="pt4" data-spacingsp="pt0">
            <div className="c-serviceTable__container--head">
              <div className="c-serviceTable__dt" />
              <p className="c-serviceTable__dd--head u-mqHidden--sm">月額サービス</p>
              <p className="c-serviceTable__dd--head u-mqHidden--sm">受託開発サービス</p>
            </div>
            <dl className="c-serviceTable__container" data-spacing="mb2">
              <dt className="c-serviceTable__dt">
                <span className="c-serviceTable__title">
                  {content.content3.solution.table1.title}
                </span>
              </dt>
              <dd className="c-serviceTable__dd">
                <p className="c-serviceTable__dd--head u-mqShow--smBlock">月額サービス</p>
                <div data-spacing="pv4" data-spacingsp="pv3">
                  <ul className="c-serviceTable__list">
                    {content3Table1monthlys.map(content3Table1monthly => (
                      <li key={content3Table1monthly + `content3Table1monthly`}>
                        {content3Table1monthly}
                      </li>
                    ))}
                  </ul>
                </div>
              </dd>
              <dd className="c-serviceTable__dd">
                <p className="c-serviceTable__dd--head u-mqShow--smBlock">受託開発サービス</p>
                <div data-spacingsp="pv3">
                  <ul data-spacing="pv4" className="c-serviceTable__list">
                    {content3Table1fiduciarys.map(content3Table1fiduciary => (
                      <li key={content3Table1fiduciary + `content3Table1fiduciary`}>
                        {content3Table1fiduciary}
                      </li>
                    ))}
                  </ul>
                </div>
              </dd>
            </dl>
            <dl className="c-serviceTable__container" data-spacing="mb2">
              <dt className="c-serviceTable__dt">
                <span className="c-serviceTable__title">
                  {content.content3.solution.table2.title}
                </span>
              </dt>
              <dd className="c-serviceTable__dd">
                <p className="c-serviceTable__dd--head u-mqShow--smBlock">月額サービス</p>
                <div data-spacing="pv4" data-spacingsp="pv3">
                  <ul className="c-serviceTable__list">
                    {content3Table2monthlys.map(content3Table2monthly => (
                      <li key={content3Table2monthly + `content3Table2monthly`}>
                        {content3Table2monthly}
                      </li>
                    ))}
                  </ul>
                </div>
              </dd>
              <dd className="c-serviceTable__dd">
                <p className="c-serviceTable__dd--head u-mqShow--smBlock">受託開発サービス</p>
                <div data-spacing="pv4" data-spacingsp="pv3">
                  <ul className="c-serviceTable__list">
                    {content3Table2fiduciarys.map(content3Table2fiduciary => (
                      <li key={content3Table2fiduciary + `content3Table2fiduciary`}>
                        {content3Table2fiduciary}
                      </li>
                    ))}
                  </ul>
                </div>
              </dd>
            </dl>
            <dl className="c-serviceTable__container">
              <dt className="c-serviceTable__dt">
                <span className="c-serviceTable__title">
                  {content.content3.solution.table3.title}
                </span>
              </dt>
              <dd className="c-serviceTable__dd">
                <p className="c-serviceTable__dd--head u-mqShow--smBlock">月額サービス</p>
                <div data-spacing="pv4" data-spacingsp="pv3">
                  <ul className="c-serviceTable__list">
                    {content3Table3monthlys.map(content3Table3monthly => (
                      <li key={content3Table3monthly + `content3Table3monthly`}>
                        {content3Table3monthly}
                      </li>
                    ))}
                  </ul>
                </div>
              </dd>
              <dd className="c-serviceTable__dd">
                <p className="c-serviceTable__dd--head u-mqShow--smBlock">受託開発サービス</p>
                <div data-spacing="pv4" data-spacingsp="pv3">
                  <ul className="c-serviceTable__list">
                    {content3Table3fiduciarys.map(content3Table3fiduciary => (
                      <li key={content3Table3fiduciary + `content3Table3fiduciary`}>
                        {content3Table3fiduciary}
                      </li>
                    ))}
                  </ul>
                </div>
              </dd>
            </dl>
          </div>  
        </div>
      </article>
    </section>
      
  </main>
)

ServicePageTemplate.propTypes = {
  heading: PropTypes.string,
  headingEn: PropTypes.string,
  title: PropTypes.string,
  titleEn: PropTypes.string,
  description: PropTypes.string,
  helmet: PropTypes.object,
}


const ServicePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  let schemaOrgJSONLD = [
    {
      '@context': 'https://schema.org',
      '@type': 'Corporation',
      '@id': "https://arbor.jp",
      url: "https://arbor.jp",
      name: "株式会社アーバ",
      alternateName: "株式会社アーバ",
      address: {
        '@type': 'PostalAddress',
        'postalCode': '372-0012',
        'addressCountry': 'JP',
        'addressRegion': '群馬県',
        'addressLocality': '伊勢崎市',
        'streetAddress': '豊城町2144−3',
      },
      founder: {
          '@type': 'Person',
          'givenName': '直人',
          'familyName': '石丸',
      },
      foundingDate: '2018-1-11',
      description: frontmatter.description, 
    },
    {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          name: "トップページ",
          position: 1,
          item: {
            "@id": "https://arbor.jp",
            "name": "株式会社アーバ",
          }
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            "@id": typeof window !== "undefined" && window.location.href,
            "name": frontmatter.title,
          }
        },
      ],
    },
  ];

  return (
    <Layout>
      <ServicePageTemplate
        title={frontmatter.title}
        titleEn={frontmatter.titleEn}
        description={frontmatter.description}
        content={frontmatter.content}
        content2monthlys={frontmatter.content.content2.solution.table1.monthly}
        content2fiduciarys={frontmatter.content.content2.solution.table1.fiduciary}
        content3Table1monthlys={frontmatter.content.content3.solution.table1.monthly}
        content3Table1fiduciarys={frontmatter.content.content3.solution.table1.fiduciary}
        content3Table2monthlys={frontmatter.content.content3.solution.table2.monthly}
        content3Table2fiduciarys={frontmatter.content.content3.solution.table2.fiduciary}
        content3Table3monthlys={frontmatter.content.content3.solution.table3.monthly}
        content3Table3fiduciarys={frontmatter.content.content3.solution.table3.fiduciary}
        helmet={
          <Helmet titleTemplate="%s | 株式会社アーバ">
            <title>{`${frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${frontmatter.description}`}
            />
            <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
          </Helmet>
        }
      />
    </Layout>
  )
}

ServicePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ServicePage

export const servicePageQuery = graphql`
  query ServicePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        titleEn
        description
        content{
          content1{
            problem{
              title
              item1
              item2
              item3
              item4
              item5
            }
            solution{
              title
              description
            }
          }
          content2{
            problem{
              title
              item1
              item2
            }
            solution{
              title
              description
              table1{
                title
                monthly
                fiduciary
              }
            }
          }
          content3{
            problem{
              title
              item1
              item2
              item3
            }
            solution{
              title
              description
              table1{
                title
                monthly
                fiduciary
              }
              table2{
                title
                monthly
                fiduciary
              }
              table3{
                title
                monthly
                fiduciary
              }
            }
          }
        }

      }
    }
  }
`
